@tailwind base;

@layer base {
  h1 {
    @apply text-8xl;
    color: red;
  }
}
@tailwind components;
@tailwind utilities;
